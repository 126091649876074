<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="姓名:">
            <el-input v-model="selectForm1.name"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="new_css">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="500"
   
        @current-change="selectCurrentRow"
        @row-dblclick="handleEdit"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="CreateDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="年" prop="Year"></el-table-column>
        <el-table-column label="月" prop="Month"></el-table-column>
        <el-table-column label="状态" prop="State">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.State == '未锁定' ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper,total"
        :page-count="total"
        :total="totaldata"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="名称:"
              prop="UserName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryForm.UserName"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="缴费基数:"
              prop="BaseSalary"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryForm.BaseSalary"
                @blur="inputMoney('BaseSalary')"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="公积金(公司):"
              prop="FactorySalary"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryForm.FactorySalary"
                @blur="inputMoney('FactorySalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公积金(个人):"
              prop="UserSalary"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryForm.UserSalary"
                @blur="inputMoney('UserSalary')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
           
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryAllFormVisible"
      v-model="SakaryAllFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryAllForm"
        :model="SakaryAllForm"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="公积金(公司):"
              prop="FactorySalary"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryAllForm.FactorySalary"
                @blur="inputAllMoney('FactorySalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公积金(个人):"
              prop="UserSalary"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                v-model="SakaryAllForm.UserSalary"
                @blur="inputAllMoney('UserSalary')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" style="text-align: center">
          
              <el-button
                type="primary"
                v-on:click="onAllSubmit('SakaryAllForm')"
                >提交</el-button
              >
          
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="公积金记录"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm" :model="selectForm">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
              <!-- 这里触发点击事件 -->

              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getInsuranceData()"
                >查询</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleInEdit()"
                >修改</el-button
              >
              <el-tooltip
                class="item"
                effect="dark"
                content="不勾选人员默认修改全部"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  :disabled="isDisabled"
                  @click="handleAllEdit()"
                  >批量修改</el-button
                >
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div class="calc-height">
          <el-table :data="InsuranceData"
                    highlight-current-row
                    border
                    height="100%"
                    @current-change="selectCurrentRow1"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="UserName" label="名称"> </el-table-column>
              <el-table-column prop="IDCard" width="200" label="身份证号">
              </el-table-column>
              <el-table-column prop="Year" label="年"> </el-table-column>
              <el-table-column prop="Month" label="月"> </el-table-column>
              <el-table-column label="缴费账户" prop="PaymentAccount"></el-table-column>
              <el-table-column label="缴费基数" prop="BaseSalary"></el-table-column>
              <el-table-column label="公司缴费(实缴)" prop="FactorySalary"></el-table-column>
              <el-table-column label="公司缴费(应缴)" prop="FactorySalary02"></el-table-column>
              <el-table-column label="个人缴费(实缴)" prop="UserSalary"></el-table-column>
              <el-table-column label="个人缴费(应缴)" prop="UserSalary02"></el-table-column>
              <el-table-column label="是否能提取" prop="isExtract" width="100" filter-placement="bottom-end">
                  <template slot-scope="scope">
                      <el-tag :type="scope.row.isExtract === true ? 'success':'warning'" disable-transitions>{{scope.row.isExtract? `是`:`否`}}</el-tag>
                  </template>
              </el-table-column>
          </el-table>
      </div>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="prev, pager, next, jumper,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  GetHouseFundsMonthList,
  AddHouseFundsMonth,
  DeleteHouseFundsMonth,
  GetHouseFundsReportList,
  UpdateHouseFundsReport,
  UpdateHouseFundsMonth,
  GetHouseFundsPersonAddExcelList,
  GetHouseFundsPersonReduceExcelList,
  GetHouseFundsPersonHistoryList,
  UpdateBatchUpdateInfoSalary,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
    return {
      searchVal: "",
      selectForm1: {
        name: "",
      },
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      multipleSelection: [],
      PId: "",
      selectForm: {
        userName: "",
      },
      SakaryForm: [],
      SakaryAllForm: {
        FactorySalary: "",
        UserSalary: "",
        PId: "",
      },
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm1.name,
      };
      console.log(para);
      GetHouseFundsMonthList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData(isCurrent = true) {
      if (isCurrent) {
        this.page1 = 1;
      }
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
      };
      GetHouseFundsReportList(para).then((res) => {
        console.log(res);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "未锁定") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      this.PId = row.Id;
      this.getInsuranceData();
      this.InsuranceVisible = true;
    },
    handleInEdit() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      this.SakaryForm = Object.assign({}, row);
      this.SakaryVisible = true;
    },
    handleAllEdit() {
      (this.SakaryAllForm = {
        FactorySalary: "",
        UserSalary: "",
        PId: "",
        UserList: [],
      }),
        (this.SakaryAllForm.PId = this.PId);
      this.SakaryAllFormVisible = true;
    },
    handleAdd() {
      AddHouseFundsMonth().then((res) => {
        if (res.data.success) {
          this.$message({
            message: "添加成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "添加失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "已锁定") {
        this.$message({
          message: "锁定状态的数据不允许删除！",
          type: "error",
        });
        return;
      }
      let para = {
        id: row.Id,
      };

      DeleteHouseFundsMonth(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData(false);
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    //导出增员
    excelIncrease() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要导出的月份数据！",
          type: "error",
        });
        return;
      }
      GetHouseFundsPersonAddExcelList({ date: row.CreateDate }).then((res) => {
        if (res.data.success) {
          console.log(res);
          var header = [
            "姓名",
            "公司",
            "身份证号",
            "性别",
            "民族",
            "手机号",
            "婚姻状况",
            "增员日期",
              "缴费基数",
              "缴费账户",
          ];
          var filter = [
            "UserName",
            "Factory",
            "IdCard",
            "Male",
            "Nationality",
            "MobilePhone",
            "MarriedStatus",
            "AddDateString",
              "BaseSalary",
              "PaymentAccount",
          ];
          var name = "公积金增员列表";
          this.excelData(res.data.response, header, filter, name);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    excelDownsiz() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要导出的月份数据！",
          type: "error",
        });
        return;
      }
      GetHouseFundsPersonReduceExcelList({ date: row.CreateDate }).then(
        (res) => {
          if (res.data.success) {
            console.log(res);
            var header = ["姓名", "身份证号", "减员日期", "备注", "缴费基数","缴费账户"];
            var filter = [
              "UserName",
              "IdCard",
              "ReduceDateString",
              "Remark",
                "BaseSalary",
                "PaymentAccount",
            ];
            var name = "公积金减员列表";
            this.excelData(res.data.response, header, filter, name);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        }
      );
    },
    excelUser() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要导出的月份数据！",
          type: "error",
        });
        return;
      }
      GetHouseFundsPersonHistoryList({ monthId: row.Id }).then((res) => {
        if (res.data.success) {
          console.log(res);
          var header = [
            "姓名",
            "公司",
            "身份证号",
            "部门",
            "民族",
            "性别",
            "生日",
            "公积金(公司)",
            "公积金(个人)",
            "缴费基数",
            "缴费账户",
            "增员日期"
          ];
          var filter = [
            "UserName",
            "Factory",
            "IdCard",
            "Dpt",
            "Nationality",
            "Male",
            "Birthday",
            "FactorySalary",
            "UserSalary",
            "BaseSalary",
            "PaymentAccount",
            "AddDate"
          ];
          var name = "公积金缴纳人员列表";
          this.excelData(res.data.response, header, filter, name);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    excelData(res, header, filter, name) {
      require.ensure([], () => {
        const {
          export_json_to_excel,
        } = require("../../../util/Export2Excel.js");
        const tHeader = header;
        const filterVal = filter;
        const list = res;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, name);
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    handleLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要锁定的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要锁定吗？", "提示", {}).then(() => {
          UpdateHouseFundsMonth({ id: row.Id, IsLock: true}).then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
      },
      handleUnLock() {
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要锁定的一行数据！",
                  type: "error",
              });
              return;
          }
          this.$confirm("确定要解锁吗？", "提示", {}).then(() => {
              UpdateHouseFundsMonth({ id: row.Id, IsLock: false}).then((res) => {
                  console.log(res);
                  if (res.data.success) {
                      this.$message({
                          message: "修改成功",
                          type: "success",
                          duration: 5000,
                      });
                      this.getData(false);
                  } else {
                      this.$message({
                          message: res.data.msg,
                          type: "error",
                          duration: 5000,
                      });
                  }
              });
          });
      },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    inputAllMoney(name) {
      if (!validate.validateIsMoney(this.SakaryAllForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          UpdateHouseFundsReport(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
    onAllSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          this.SakaryAllForm.UserList = this.multipleSelection;
          let para = Object.assign({}, this.SakaryAllForm);
          console.log(this.SakaryAllForm);
          //   console.log(this.multipleSelection)
          UpdateBatchUpdateInfoSalary(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryAllFormVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: res.data.masg,
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

